@import './abstracts/variables';

.save-product-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: none;
}

.header-divider {
  margin: 20px -40px;
}

.linebreak-spacer {
  display: block;
}

.select-customer-modal {
  :global {
    .rs-modal-body {
      margin-top: 0;
      text-align: left;

      @media @below-sm {
        margin-top: @spacer;
      }
    }
  }

  .modal-content-stack {
    :global(.rs-stack-item) {
      width: 100%;
    }
  }
}

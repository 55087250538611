@import 'abstracts/variables';
@import 'abstracts/helpers';
@import 'abstracts/droppe-theme';

@border-gray: @B200; // same as @panel-border in rsuite

.product-card-discount-tag {
  padding: 2px 5px;

  :global(.rs-icon) {
    width: 15px;
    height: 15px;
  }
}

.tag {
  width: fit-content;
  max-width: 210px; // max width before ellipses

  &.z-index-1 {
    position: relative;
    z-index: 1;
  }

  @position-bottom-half: -(( @line-height-computed + (@padding-extra-small-vertical * 2)) / 2); // Half height of tag

  &.top-tag {
    position: absolute;
    margin-left: @spacer * 2;
    margin-top: @spacer * 2;
  }

  &.left-tag {
    position: absolute;
    top: @position-bottom-half;
    left: @spacer * 2;
  }

  &.right-tag {
    position: absolute;
    top: @position-bottom-half;
    right: @spacer * 2;
  }

  &.highlight {
    @color: @B600;

    :global(.rs-icon) {
      color: @color;
      padding-right: @spacer;
    }

    color: @color;
  }

  &.use-case {
    padding-left: @spacer * 1.5;
    padding-right: @spacer * 1.5;
    border-radius: 50px;
    border-color: @primary-darker !important;
    color: @primary-darker;

    &.active-use-case {
      border-color: @teal-dark;
      color: @teal-dark;
      padding-right: 27px; // Keep space for close icon
    }

    :global(.rs-tag-text) {
      display: flex;
      align-items: center;
      min-width: 0;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        flex-shrink: 0;
        margin-right: @spacer * 0.5;
      }
    }
  }

  &.bordered {
    border: 1px solid @border-gray;
    background-color: white;
  }

  &.primary-light {
    background-color: lighten(@primary, 20%);
  }

  @media @below-md {
    max-width: 170px; // Mobile max width to prevent from overflowing product card
  }
}

.discount-tag {
  border: 1px solid @teal-dark;
  border-radius: @spacer * 10;
  background: @teal-mid;
  color: @dim-mid;
  font-size: @font-size-base - 2;
  font-weight: 600;
  padding-bottom: 0;

  .strike-through {
    text-decoration: line-through;
  }
}

.address-tag {
  font-size: @font-size-small;
  border: 1px solid @primary-darker;
  border-radius: 100px;
  background: @teal-mid;
  font-weight: 600;
  color: @primary-darker;
  display: inline;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
}

.coupon-tag {
  height: @line-height-base * @font-size-base;

  .tag-icon {
    stroke: @dim-mid;
    stroke-width: 1px;
  }
}

.coupon-value {
  color: @warning-dark;
}
